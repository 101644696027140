import DropDownMenu_SolidColorMenuButtonSkinComponent from '@wix/thunderbolt-elements/src/components/DropDownMenu/viewer/skinComps/SolidColorMenuButtonSkin/SolidColorMenuButtonSkin.skin';


const DropDownMenu_SolidColorMenuButtonSkin = {
  component: DropDownMenu_SolidColorMenuButtonSkinComponent
};


export const components = {
  ['DropDownMenu_SolidColorMenuButtonSkin']: DropDownMenu_SolidColorMenuButtonSkin
};


// temporary export
export const version = "1.0.0"
